



































































import './scss/BasePopup.scss';
import './scss/BasePopupAdaptive.scss';
import './scss/PopupAddDocument.scss';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { makeFocusInput } from '@utility/makeFocusInput.js';

@Component( {
    name: 'BasePopup',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})

export default class BasePopup extends Vue {
    $refs!: { basePopupContent: any }

    @Prop({}) title!: string;
    @Prop({}) textActionSuccess!: string;
    @Prop({}) textActionAdditional!: string;
    @Prop({ default: false }) defaultOpen!: boolean;
    @Prop({ default: false }) disabledEscKey!: boolean;
    @Prop({ default: false }) overflowYHidden!: boolean;
    @Prop({ default: true }) validationPassed!: boolean;
    @Prop({ default: false }) hideCancelAction!: boolean;
    @Prop({ default: false }) hideSuccessAction!: boolean;
    @Prop({ default: 'Отмена' }) textActionCancel!: string;
    @Prop({ default: false }) hideDefaultActions!: boolean;
    @Prop({ default: false }) separateLineUnderActions!: boolean;
    @Prop({ default: false }) preloaderOnSuccessButton!: boolean;
    @Prop({ default: 'default' }) typeSuccessButton!: string;

    popupOpen = this.defaultOpen || false;
    escKeyCode = 27;
    isClickOnPopup: boolean = false;

    @Watch('disabledEscKey')
    watchDisabledEscKey() {
        if (!this.disabledEscKey) {
            document.addEventListener('keyup', this.checkEscKeyUp);
        } else {
            document.removeEventListener('keyup', this.checkEscKeyUp);
        }
    }

    emitClose() {
        this.$emit('close');
    }

    emitSuccess() {
        this.$emit('success');
    }

    emitAdditional() {
        this.$emit('action-additional');
    }

    checkEnterKeyUpInField(e) {
        if (e.key === 'Enter' && e.target && e.target.tagName && e.target.tagName === 'INPUT') {
            this.emitSuccess();
        }
    }

    openPopup() {
        this.popupOpen = true;
        this.isClickOnPopup = true;

        document.addEventListener('click', this.checkBasePopup);
        makeFocusInput(this.$refs.basePopupContent);

        if (!this.disabledEscKey) {
            document.addEventListener('keyup', this.checkEscKeyUp);
        }
        this.$emit('open');
    }

    checkBasePopup(e): void {
        if (!this.isClickOnPopup && !e.target.closest('.base-popup')) {
            this.closePopup();
        }

        this.isClickOnPopup = false;
    }

    checkEscKeyUp(e) {
        if (e.keyCode && e.keyCode === this.escKeyCode) {
            this.closePopup();
        }
    }

    checkClosePopup() {
        if (this.popupOpen) {
            this.closePopup();
        }
    }

    closePopup() {
        document.removeEventListener('keyup', this.checkEscKeyUp);
        document.removeEventListener('click', this.checkBasePopup);

        this.popupOpen = false;
        this.emitClose();
    }

    onCancelClick() {
        this.closePopup();
        this.$emit('cancel');       // нужно для различия просто заркытия попапа и нажатия "Отмена"
    }
}
